import Vue from "vue";
import VueRouter from "vue-router";

import landingPage from "../components/Home";
import aboutPage from "../components/About";
import servicesPage from "../components/Services";
import storePage from "../components/Store";

const routes = [
  {
    component: landingPage,
    path: "/",
  },
  {
    component: aboutPage,
    path: "/about",
  },
  {
    component: servicesPage,
    path: "/services",
  },
  {
    component: storePage,
    path: "/store",
  },
];

Vue.use(VueRouter);
const router = new VueRouter({ mode: "history", routes });

export default router;
