<template>
  <div>
    <NavbarComponent></NavbarComponent>

    <section class="hero has-text-centered">
      <div class="hero-body">
        <br />
        <p class="title">
          About us
        </p>
      </div>
    </section>

    <section class="section">
      <div class="container is-max-desktop">
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-6 is-vertical is-parent">
            <div class="tile is-child box">
              <p class="title">Who we are</p>
              <p>
                GuldenTech was started in 2018 by Ryan Gulden, a computer
                science graduate and full stack developer. His main specialty is
                JavaScript development (frontend and backend), cloud
                infrastructure and REST API development (Python FastAPI).
                GuldenTech was founded on the belief that containerization is
                the key to setting up any successful continuous integration and
                continuous delivery lifecycle. Any application that is
                administered by us will be containerized with Docker and then
                deployed and managed utilizing Kubernetes.
              </p>
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child box">
              <p class="title">Our mission</p>
              <p>
                The mission of Gulden Technology is to bring enterprise
                technology & processes into the home environment and smaller
                businesses/organizations. Most people and smaller
                businesses/organizations don’t have the IT budget needed to
                develop applications and resources using the latest technology.
                GuldenTech steps in to bring years of experience & knowledge to
                the table to help develop your IT posture to align with a
                dynamic and everchanging industry.
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </section>
    <br /><br /><br />
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import NavbarComponent from "./CustomNavbar.vue";
import FooterComponent from "./CustomFooter.vue";

export default {
  name: "aboutPage",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
