<template>
  <div>
    <NavbarComponent></NavbarComponent>

    <section class="hero has-text-centered">
      <div class="hero-body">
        <br />
        <p class="title">
          Services
        </p>
      </div>
    </section>

    <section class="section">
      <div class="container is-max-desktop">
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-4 is-vertical is-parent">
            <div class="tile is-child box">
              <b-icon pack="fas" size="is-large" icon="server"></b-icon>
              <p class="title">Hosting service</p>
              <p>
                GuldenTech is your one stop shop for your application hosting
                needs. We provide a easy to use web interfact to deploy your
                applications to our kubernetes clusters. Automation is baked
                into the platform, with a end to end CI/CD setup you will no
                longer waste time on testing/building/deploying your apps
                manually.
              </p>
              <hr />
              <p>Please email us at the email below for pricing.</p>
            </div>
            <div class="tile is-child box">
              <b-icon pack="fas" size="is-large" icon="database"></b-icon>
              <p class="title">Storage service</p>
              <p>
                GuldenTech provides storage solutions for all types of needs.
                Rather it be a SQL database, NoSQL, Object storage or caching -
                we have it.
              </p>
              <hr />
              <p>Please email us at the email below for pricing.</p>
            </div>
          </div>
          <div class="tile is-parent">
            <div class="tile is-child box">
              <b-icon pack="fas" size="is-large" icon="users"></b-icon>
              <p class="title">Consulting service</p>
              <p>
                With years of experience working in the industry, we are here to
                help your small IT setup leverage the latest cutting edge
                technology used throughout the industry. Cost efficiency is our
                number one thing in mind - we will find open source solutions
                for whatever your need is. If your request is not listed below,
                please reach out to the email below for a general consultation
                query to see if its something we can help on.
              </p>
              <br />
              <p>
                We are able to consult and help on some of the following areas
                (click on the box):
              </p>
              <br />
              <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3"
              >
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                  >
                    <p class="card-header-title">
                      At home
                    </p>
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="content">
                    <p>Home devices</p>
                    <li>Router installation and troubleshooting</li>
                    <li>Network configuration</li>
                    <li>Wifi examination, speed tests & security audits</li>
                    <li>Networking, VLANs and firewalls</li>
                  </div>
                </div>
              </b-collapse>
              <br />
              <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3"
              >
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                  >
                    <p class="card-header-title">
                      General technology
                    </p>
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="content">
                    <p>Programming</p>
                    <li>Backend (Python FastAPI or Express.js)</li>
                    <li>Frontend (Vue)</li>
                    <li>Function as a service</li>
                    <li>Static websites</li>
                    <hr />
                    <p>Platforms</p>
                    <li>Docker (image building)</li>
                    <li>Kubernetes</li>
                    <li>Vault</li>
                    <li>Apache Airflow</li>
                    <li>Concourse</li>
                  </div>
                </div>
              </b-collapse>
              <br />
              <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3"
              >
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    aria-controls="contentIdForA11y3"
                  >
                    <p class="card-header-title">
                      Cloud
                    </p>
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                      </b-icon>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="content">
                    <p>AWS Services</p>
                    <li>Compute - EC2 & Lambda</li>
                    <li>Storage - S3 & DynamoDB</li>
                    <li>Access/Secrets - IAM & Secrets manager</li>
                    <li>Networking - Security groups & VPCs</li>
                  </div>
                </div>
              </b-collapse>

              <hr />
              <p>Please email us at the email below for pricing.</p>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
    </section>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import NavbarComponent from "./CustomNavbar.vue";
import FooterComponent from "./CustomFooter.vue";

export default {
  name: "servicessPage",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
