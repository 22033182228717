<template>
  <div>
    <NavbarComponent></NavbarComponent>
    <section class="hero is-medium is-light">
      <div class="hero-body">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="container has-text-centered is-max-desktop">
              <b-image
                  :src="require('@/assets/logo.png')"
                  alt="GuldenTech"
              ></b-image>
              <br>
              <p class="title">
                Setting a <l>Gulden</l> standard for technology.
              </p>
              <p class="subtitle">
                GuldenTech is a platform as a service (PaaS) and IT consulting
                company aiming to simplify platform services and increase
                operational efficiency.
              </p>
              <div class="buttons is-centered">
                <b-button 
                  type="is-dark" 
                  tag="a" 
                  href="https://overseerr.guldentech.com" 
                  target="_blank"
                  >Plex Requests</b-button
                >
                <b-button
                  type="is-dark"
                  tag="a"
                  href="https://guldentech.github.io/guldentech-docs"
                  target="_blank"
                  >Developer Center</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-parent has-text-centered">
            <article class="tile is-child box">
              <b-icon
                pack="fas"
                size="is-large"
                icon="sync-alt"
                custom-class="fa-spin"
              >
              </b-icon>
              <h1 class="title">Innovation</h1>
              <br />
              <h2 class="subtitle">
                Delivering a modern framework to
                <strong>deploy, maintain, monitor and scale</strong>
                applications on a secure platform that is highly efficient and
                practical for both experienced and novice users.
              </h2>
            </article>
          </div>
          <div class="tile is-parent has-text-centered">
            <article class="tile is-child box">
              <b-icon pack="fas" size="is-large" icon="medal"></b-icon>
              <h1 class="title">Excellence</h1>
              <br />
              <h2 class="subtitle">
                Providing a <strong>reliable and robust platform</strong> that
                is highly available and fault-tolerant, GuldenTech is equipped
                with failover mechanisms to ensure your applications will always
                be running.
              </h2>
            </article>
          </div>
          <div class="tile is-parent has-text-centered">
            <article class="tile is-child box">
              <b-icon pack="fas" size="is-large" icon="handshake"></b-icon>
              <h1 class="title">Integrity</h1>
              <br />
              <h2 class="subtitle">
                GuldenTech is dedicated to operating with
                <strong>honesty</strong> and providing you with the technical
                expertise you need to entrust your business with us.
              </h2>
            </article>
          </div>
        </div>
      </div>
    </section>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import NavbarComponent from "./CustomNavbar.vue";
import FooterComponent from "./CustomFooter.vue";

export default {
  name: "landingPage",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
