<template>
  <div>
    <NavbarComponent></NavbarComponent>
    <section class="section">
      <div class="container is-max-desktop">
        <br />
        <div class="box has-text-centered">
          <h1 class="title">GuldenTech classic tees</h1>
          <h2 class="subtitle">Sizes available: S M L XL</h2>
        </div>
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-image">
                <figure class="image is-10by3">
                  <img src="../assets/front-shirt.jpg" alt="t-shirt" />
                </figure>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card">
              <div class="card-image">
                <figure class="image is-10y3">
                  <img src="../assets/back-shirt.jpg" alt="t-shirt" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="box has-text-centered">
          <h1 class="title">Price & availability</h1>
          <h2 class="subtitle">Sold out! Check back soon!</h2>
        </div>
        <br />
        <div class="box has-text-centered">
          <h1 class="title">Interested in owning a guldentech NFT?</h1>
          <h2 class="subtitle">Check out our collection on <a href="https://opensea.io/assets/guldentech">opeansea.io here!</a></h2>
        </div>
      </div>
      <br /><br /><br />
    </section>
    <FooterComponent></FooterComponent>
  </div>
</template>
<script>
import NavbarComponent from "./CustomNavbar.vue";
import FooterComponent from "./CustomFooter.vue";

export default {
  name: "storePage",
  components: {
    NavbarComponent,
    FooterComponent,
  },
};
</script>
