<template>
  <footer class="footer">
    <div class="container has-text-centered">
      <div class="content">
        <img
          alt="..."
          src="../assets/footer_new.png"
          class="max-w-full mx-auto"
          style="max-width: 230px;"
        />
        <br>
        <p>Copyright © {{ date }} GuldenTech, LLC.</p>
        <div class="buttons is-centered">
          <b-button tag="a" href="mailto:contact@guldentech.com">
            <b-icon pack="fas" size="is-small" icon="envelope"></b-icon>
            <span>Email us at contact@guldentech.com</span>
          </b-button>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
